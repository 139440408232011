import React from 'react'
import { Typography, Card } from "@mui/material";
import Layout from '../layouts';

const ResourcesPage = () => (
    <Layout pageName="Resources">
        <Card style={{padding:'20px'}}>
            <Typography variant="h6" style={{color:'#080',fontWeight:'bold'}}>Recommended Books:</Typography>

            <p><em>Caste</em> — Isabel Wilkerson</p>
            <p><em>Blindspot: Hidden Biases of Good People</em> — Mahzarin R. Banaji and Anthony G. Greenwald</p>
            <p><em>Managing Transitions</em> — William Bridges</p>
            <p><em>7 Habits of Highly Effective People</em> — Stephen Covey</p>
            <p><em>Loving What Is</em> — Byron Katie</p>
            <p><em>The Power of Now</em> — Eckhart Tolle</p>
            <p><em>Emotional Intelligence</em> — Daniel Goleman</p>
            <p><em>The Heart Aroused</em> — David Whyte</p>
            <p><em>Gradual Awakening</em> — Stephen Levine</p>


            <br/>
            <Typography variant="h6" style={{color:'#080',fontWeight:'bold'}}>Recommended Websites:</Typography>
            <br/>
            <div><a target="_blank" rel="noreferrer" href="http:\\thework.org">thework.org</a></div>
        </Card>
    </Layout>
);

export default ResourcesPage;
